import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaClientesProvCoor } from './../../apis/api-cliente.js'

import { OrdenaArray2Campos } from "./../../config/funcionesPropias.js";


const InfClientes = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    const [estado, setEstado] = useState(
        {
            //carga: true,
            mercado: undefined,
            seleccionMercados: [],
            lineasClientes: [],
            lineasExcel: []
        }
    )

    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        //console.log("CargaMercados")
        if (state.mercadosProveedor.length > 0) {
            actions.cambiarMensaje("")
            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = [t("MisInformes.Todos")]
            state.mercadosProveedor.forEach(mercado => {
                listaMercados.push(state.idioma === "Es" ? mercado.MerDesC : mercado.MerDesE)
            });
            /*
            var listaMercados = []
            for (let i = 0; i < state.mercadosProveedor.length; i++) {
                if (i === 0) {
                    listaMercados.push(t("MisInformes.Todos"))
                }
                listaMercados.push(
                    state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
                )
            }
            */
            setEstado(estado => ({ ...estado, mercado: 0, seleccionMercados: listaMercados }))
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, state.idioma, actions, t])

    // Carga cuando cambia el mercado. //
    useEffect(() => {
        //if (estado.carga) {
        loadClientesIniciales()
        // Actualiza el estado sin Repintar. //
        //    estado.carga = false
        //}
    }, [estado.mercado])


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadClientesIniciales()
    }


    // Contar los productos para establecer el calendario
    async function loadClientesIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasClientes.length > 0) {
            setEstado({ ...estado, lineasClientes: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (estado.mercado !== undefined && state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaClientesProvCoor({ proveedor: state.codProveedor, mercado: mercadosFiltro }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }



    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        if (data.length > 0) {
            data = OrdenaArray2Campos(data, state.idioma === "Es" ? "MerDesC" : "MerDesE", "CliNom")
//console.log("data:", data)            
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.Mercado"),
                t("MisInformes.Cliente"),
                t("MisInformes.DNI-NIF"),
                t("MisInformes.Email"),
                t("MisInformes.Telefono"),
                t("MisInformes.Direccion"),
                t("MisInformes.CodigoPostal"),
                t("MisInformes.Poblacion"),
                t("MisInformes.Provincia")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Cliente: data[i].CliNom,
                    NIF: data[i].CliNif,
                    Email: data[i].CliEma,
                    Telefono: data[i].CliTel,
                    Direccion: data[i].DirCal,
                    CodigoPostal: data[i].DirCpo,
                    Poblacion: data[i].DirPob,
                    Provincia: data[i].DirPro
                })
                resultadoExcel.push({
                })
                resultadoExcel[i][cabeceraExcel[0]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                resultadoExcel[i][cabeceraExcel[1]] = data[i].CliNom
                resultadoExcel[i][cabeceraExcel[2]] = data[i].CliNif
                resultadoExcel[i][cabeceraExcel[3]] = data[i].CliEma
                resultadoExcel[i][cabeceraExcel[4]] = data[i].CliTel
                resultadoExcel[i][cabeceraExcel[5]] = data[i].DirCal
                resultadoExcel[i][cabeceraExcel[6]] = data[i].DirCpo
                resultadoExcel[i][cabeceraExcel[7]] = data[i].DirPob
                resultadoExcel[i][cabeceraExcel[8]] = data[i].DirPro
            }

            estado.lineasClientes = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasClientes: resultado, lineasExcel: resultadoExcel })

            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayClientes"))
        }

    }


    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.Clientes")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            id={"Mer"}
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Box sx={{ ml: 3 }}>
                        <BotonXlsx data={estado.lineasExcel} filename={t("MisInformes.Clientes")} />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )



    const listaLineasListado = (
        estado.lineasClientes.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Cliente")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.DNI-NIF")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Email")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Telefono")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Direccion")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.CodigoPostal")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Poblacion")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Provincia")}</Typography></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasClientes.map((l, i) => (

                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.Mercado}</TableCell>
                                    <TableCell>{l.Cliente}</TableCell>
                                    <TableCell>{l.NIF}</TableCell>
                                    <TableCell>{l.Email}</TableCell>
                                    <TableCell>{l.Telefono}</TableCell>
                                    <TableCell>{l.Direccion}</TableCell>
                                    <TableCell>{l.CodigoPostal}</TableCell>
                                    <TableCell>{l.Poblacion}</TableCell>
                                    <TableCell>{l.Provincia}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaLineasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfClientes)