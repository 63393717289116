import React, { useState } from "react";
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'
import { withTranslation } from 'react-i18next'

import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from "@mui/material/Select";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import { alpha, styled } from '@mui/material/styles';

// Castellano //
import "dayjs/locale/es";
// Euskera //
import "dayjs/locale/eu";

import CajaTexto from './../../components/CajaTexto.js';
import { /*BuscaIndice,*/ EsUsuarioEspecial, FechaAaaaMmDd, AaaaMmDd_a_Fecha } from "../../config/funcionesPropias.js";


const DialogCrearModificarPunto = ({ t, ...props }) => {
  // Ajusta cosas que no llegan
  //let margenIzquierdo = props.margenI ? props.margenI : 0
  const { state, /*actions*/ } = useStore(store);

  const usuarioEspecial = (EsUsuarioEspecial(state.emailUsuario) !== -1)

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 13,
      width: 'auto',
      padding: '2px 2px 2px 14px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  // Manejador para Abrir y Cerrar la Ventana del Dialog
  const [open, setOpen] = useState(true);

  /*
  const handleClickOpen = () => {
    setOpen(true);
  }
  */

  // Llega el punto con sus familias, le añadimos todas las familias
  let puntoRecibido = props.punto
  let familiasDelPunto = []
  let indice
  let familiaActiva
  let fechaActual = new Date()
  fechaActual.setMonth(fechaActual.getMonth() + 4)   // Sumar 4 meses a hoy

  //console.log("Punto:", puntoRecibido, state.familiasSubfamilias)

  //state.familias.forEach(f => {
  state.familiasSubfamilias.forEach(f => {
    let fechaFamilia
    //indice = BuscaIndice(puntoRecibido.familiasMias, "codFamilia", f.FamCod)
    indice = puntoRecibido.familiasMias.findIndex(fm => fm.codFamilia + fm.codSubFamilia === f.FamCod + f.SubCod);
    let nombreFS = ""

    if (indice !== -1) {
      familiaActiva = true
      // Coger la fecha de la familia actual (dd-mm-aaaa). //
      let aFecha = puntoRecibido.familiasMias[indice].fecha.split("-")
      // Convertir fecha a aaaa-mm-dd. //
      fechaFamilia = aFecha[2] + "-" + aFecha[1] + "-" + aFecha[0]
    } else {
      familiaActiva = false
      // Por defecto la fecha de hoy + 4 meses. //
      fechaFamilia = FechaAaaaMmDd(fechaActual)
    }
    nombreFS = state.idioma === "Es" ? f.FamDesC : f.FamDesE
    if (f.SubCod !== "") {
      nombreFS += " - " + (state.idioma === "Es" ? f.SubDesC : f.SubDesE)
    }
    familiasDelPunto.push({
      Codigo: f.FamCod + f.SubCod,
      Nombre: nombreFS, // state.idioma === "Es" ? f.FamDesC : f.FamDesE,
      Fecha: fechaFamilia,
      Activa: familiaActiva
    })
  })
  puntoRecibido.nuevasFamilias = familiasDelPunto
  const [punto, setPunto] = useState(puntoRecibido);


  const handlePublicar = () => {
    punto.visible = 1
    setOpen(false);
    props.actualizador(2, punto)
  }


  const handleDespublicar = () => {
    punto.visible = 0
    setOpen(false);
    props.actualizador(3, punto)
  }


  const handleCancelar = () => {
    setOpen(false);
    props.actualizador(0)
  }

  const handleCloseGuardar = () => {
    // Si el punto no tiene Nombre/Titulo. //
    if (punto.titulo.trim() === '') {
      alert(t("MisMapas.ErrorNombre"))
    } else {
      setOpen(false);
      props.actualizador(punto.modificado ? 1 : 0, punto)
    }
  }


  const handleAmpliarFechas = () => {
    //console.log("Punto:", punto)   
    // Actualizar Pantalla y array para registrar. //
    punto.nuevasFamilias.forEach((f, i) => {
      // Si la familia está Activa. //
      if (f.Activa) {
        // Convertir formato aaaa-mm-dd a una Fecha. //
        let fecha = AaaaMmDd_a_Fecha(f.Fecha)
        // Sumar 6 meses a la fecha. //
        fecha.setMonth(fecha.getMonth() + 6)
        // Actualizar Fecha. //
        f.Fecha = FechaAaaaMmDd(fecha)
      }
    })
    // Actualizar Estado. //
    punto.modificado = true
    setPunto({ ...punto })
  }


  const handleChangePunto = (campo, m) => event => {
    //event.stopPropagation()
    ///console.log('campo = ', campo, " --  event = ", event)
    if (campo === "Tipo") {
      punto.tipo = event.target.value
    } else if (campo === "Check") {
      punto.nuevasFamilias[m].Activa = event.target.checked
    } else if (campo === "Titulo") {
      punto.titulo = event
    } else if (campo === "Poblacion") {
      punto.poblacion = event
    } else if (campo === "Latitud") {
      punto.posicion.lat = event
    } else if (campo === "Longitud") {
      punto.posicion.lng = event
    } else if (campo === "Nota") {
      punto.nota = event
    } else if (campo === "Mercado") {
      punto.mercados[m].Incluido = !punto.mercados[m].Incluido
      punto.mercados[m].Modificado = true
    }
    // Actualizar Estado. //
    punto.modificado = true
    setPunto({ ...punto })
  }


  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleCancelar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'span'}>
            <Typography variant="azul15">{t("MisMapas.TipoUbicacion")}:</Typography>
            <FormControl
              sx={{ ml: 1, mt: - 0.5, mb: 3 }}
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
            >
              <Select
                id={"PunTip" + punto.tipo}
                //autoFocus
                value={punto.tipo}
                //onChange={handleFiltroIvas}
                onChange={handleChangePunto("Tipo")}
                input={<BootstrapInput id={"MenuTipos" + punto.tipo} />}
              >
                {state.tiposDePuntos.map((tp, i) => (
                  <MenuItem value={i} key={i}> {tp} </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Typography variant="azul15">{t("MisMapas.Nombre")}:</Typography>
            <FormControlLabel sx={{ ml: 3 }}
              aria-label="Acknowledge"
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<CajaTexto
                id={"PunTit" + punto.titulo}
                tipo={"string"}
                ancho={425}
                valor={punto.titulo}
                accion={handleChangePunto("Titulo")}
              />}
            />
            <br />
            <Typography variant="azul15">{t("MisMapas.Ubicacion")}:</Typography>
            <FormControlLabel sx={{ ml: 1 }}
              aria-label="Acknowledge"
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<CajaTexto
                id={"PunPob" + punto.poblacion}
                tipo={"string"}
                ancho={425}
                valor={punto.poblacion}
                accion={handleChangePunto("Poblacion")}
              />}
            />
            <br />
            <Typography variant="azul15">{t("MisMapas.LatLon")}:</Typography>
            <FormControlLabel sx={{ ml: 1 }}
              aria-label="Acknowledge"
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<CajaTexto
                id={"PunLat" + punto.posicion.lat}
                tipo={"number"}
                ancho={200}
                valor={punto.posicion.lat}
                accion={handleChangePunto("Latitud")}
              />}
            />
            <FormControlLabel sx={{ ml: 1 }}
              aria-label="Acknowledge"
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<CajaTexto
                id={"PunLon" + punto.posicion.lng}
                tipo={"number"}
                ancho={200}
                valor={punto.posicion.lng}
                accion={handleChangePunto("Longitud")}
              />}
            />
            <Typography variant="azul15">{t("MisMapas.Nota")}:</Typography>
            <FormControlLabel sx={{ ml: 1 }}
              aria-label="Acknowledge"
              disabled={(punto.productor !== state.codProveedor && !usuarioEspecial) || (punto.visible === 1)}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<CajaTexto
                id={"PunNot" + punto.nota}
                tipo={"string"}
                ancho={425}
                valor={punto.nota}
                accion={handleChangePunto("Nota")}
              />}
            />
            {usuarioEspecial &&
              <div>
                <Divider sx={{ mt: 1.5, mb: 1 }} />
                <Typography variant="azul15">{t("MisMapas.Mercados")}:</Typography>
                {punto.mercados.map((m, i) => (
                  <Grid
                    key={"Mercado" + m.MerCod + i}
                    //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    sx={{
                      border: 0,
                      paddingTop: 1, paddingBottom: 1,
                      height: 24
                    }}
                  >
                    <Checkbox
                      sx={{ width: 40, height: 24 }}
                      id={"Mercado" + m.MerCod + i}
                      color="primary"
                      checked={m.Incluido}
                      onChange={handleChangePunto("Mercado", i)}
                    />
                    <Typography variant="gris13">{m.MerDesC}</Typography>
                  </Grid>
                ))}
              </div>
            }
            <Divider sx={{ mt: 1.5, mb: 1 }} />
            <Typography variant="azul15">{t("MisMapas.Familias")}:</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} size="small" aria-label="tabla de familias">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("MisMapas.Activa")}</TableCell>
                    <TableCell>{t("MisMapas.Familia-Subfamilia")}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ width: 140, height: 35 }}
                        onClick={handleAmpliarFechas}>
                        {t("MisMapas.AmpliarFechas")}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {punto.nuevasFamilias.map((f, i) => (
                    <TableRow
                      key={"Familias" + f + i}
                      //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      sx={{
                        border: 0,
                        paddingTop: 1, paddingBottom: 1
                      }}
                    >
                      <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }}>
                        <Checkbox
                          sx={{ width: 40, height: 24 }}
                          id={"Familia" + f + i}
                          color="primary"
                          checked={f.Activa}
                          onChange={handleChangePunto("Check", i)}
                        />
                      </TableCell>
                      <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }}>
                        <Typography variant="gris13">{f.Nombre}</Typography>
                      </TableCell>
                      <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} component="th" scope="row">
                        <Typography variant="gris13">{f.Activa ? f.Fecha.substr(8, 2) + f.Fecha.substr(4, 4) + f.Fecha.substr(0, 4) : ""}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {punto.visible === 0 && usuarioEspecial &&
            <Button onClick={handlePublicar}>Publicar</Button>
          }
          {punto.visible === 1 && usuarioEspecial &&
            <Button onClick={handleDespublicar}>Des-Publicar</Button>
          }
          <Button onClick={handleCancelar}>{t("MisMapas.Cancelar")}</Button>
          <Button onClick={handleCloseGuardar} autoFocus>{t("MisMapas.Aceptar")}</Button>
        </DialogActions>
      </Dialog>
    </Grid >
  )
}
export default withTranslation()(DialogCrearModificarPunto)