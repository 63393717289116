import React, { Fragment, useCallback, useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'
import { withTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { listaSolicitudes } from './../../apis/api-solicitud.js'
import { enviaEmailSolicitud } from './../../apis/api-email.js'

import MensajeCabecera from "../../components/MensajeCabecera.js";


const Clientes = ({ t, ...props }) => {
    const { state, actions } = useStore(store);
    const [estado, setEstado] = useState(
        {
            carga: true,
            lineasClientes: []
        }
    )

    
    // Cargar las Facturas. //
    const loadSolicitudesIniciales = useCallback(async () => {
        let params = {
            proveedor: state.codProveedor
        }
        await listaSolicitudes(params).then((data) => {
            if (data.error) {
                console.log(data.error)
            }
            else {
                if (data.length > 0) {
                    //console.log("Data: ", data)                
                    // Añade AceptarSolicitud
                    data.map(l => {
                        l.Aceptar = false
                        l.Denegar = false
                        return l
                    })
                    //console.log('datos = ', data)
                    // Fuerza Renderizado
                    setEstado(estado => ({ ...estado, lineasClientes: data }))
                    actions.cambiarMensaje("")
                } else {
                    actions.cambiarMensaje(t("Clientes.NoHaySolicitudesDeClientes"))
                }
            }
        })
    }, [actions, state.codProveedor, t])


    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        if (estado.carga) {
            loadSolicitudesIniciales()
            //estado.carga = false
            setEstado(estado => ({ ...estado, carga: false }))
        }
    }, [estado.carga, loadSolicitudesIniciales])



    const handleChange = (campo, linea) => event => {
        //event.stopPropagation()
        if (campo === "Aceptar") {
            linea.Aceptar = !linea.Aceptar
            linea.Denegar = linea.Aceptar === true ? linea.Denegar = false : linea.Denegar
        } else {
            linea.Denegar = !linea.Denegar
            linea.Aceptar = linea.Denegar === true ? linea.Aceptar = false : linea.Aceptar
        }
        setEstado({ ...estado, lineasClientes: estado.lineasClientes })
    }


    const handleSubmit = async () => {
        let clientes = []
        estado.lineasClientes.forEach(l => {
            if (l.Aceptar || l.Denegar) {
                clientes.push(l)
            }
        })
        // Envía un mail a cada cliente. //
        clientes.forEach(cliente => {

            let mercado = state.idioma === "Es" ? cliente.MerDesC : cliente.MerDesE
            let asunto = "Alta eskaera- Solicitud de alta"
            let msgEU
            let msgES
            let CooTel = cliente.CooTel
            let CooMai = cliente.CooMai
            if (cliente.Aceptar) {
                /*
                asunto = t("Clientes.SolicitudAceptada")
                mensaje = "Hola " + cliente.CliNom + "<hmtl><br/></html>"
                mensaje += "Su solicitud de participación en el mercado " + mercado + " ha sido "
                mensaje += cliente.Aceptar ? "aceptada" : "rechazada"
                mensaje += "Kaixo " + cliente.CliNom + "<hmtl><br/></html>"
                mensaje += mercado + " merkatuan parte hartzeko egin "
                mensaje += cliente.Aceptar ? "duzun eskaera onartu egin da." : "zenuen eskaera atzera bota dute."
                */
                msgEU = "<p>Sareko.net plataformako merkatu berri batean erosketak egiteko zure eskaera onartua izan da. "
                msgEU += "Eskaeran proposatzen zenuen erabiltzailea eta gakoa erabilita sartu ahal izango zara "
                msgEU += mercado + " merkatuan. Mila esker eta on egin!. </p>"
                msgEU += "<p>Aurrerantzean erosketaren inguruan zalantza edo kexaren bat izango bazenu merkatuko "
                msgEU += "koordintzailearekin jarri zaitezke kontaktuan "
                msgEU += "telf. " + CooTel + " edo email: " + CooMai + ". </p>"
                msgEU += "<p>Sistemaren inguruko zalantzak balira zuzenean gurekin harremanetan jarri, sustatu@ekolurra.eus idatzita. </p>"
                msgEU += "<p>Ondo izan. ENEEK-Ekolurrako komunikazio saila.</p>"
                //
                msgES = "<p>Ha sido aceptada tu solicitud de alta para realizar la compra en el mercado de Sareko.net. "
                msgES += "Podrás acceder al mercado " + mercado + " utilizando el usuario y la contraseña definida en la solicitud de alta. "
                msgES += "¡Muchas gracias y buen provecho! </p>"
                msgES += "<p>En caso de tener alguna duda o cuestión con alguno de los pedidos realizados "
                msgES += "ponte en contacto con la persona coordinadora del mercado. "
                msgES += "Telf. " + CooTel + " o email " + CooMai + ". </p>"
                msgES += "<p>Sin embargo, en caso de tener alguna cuestión con el programa "
                msgES += "contacta con los técnicos de comunicación del Consejo: sustatu@ekolurra.eus. </p>"
                msgES += "<p>Un saludo. Departamento de comunicación de ENEEK-Ekolurra.</p>"
            } else {
                /*
                asunto = t("Clientes.SolicitudDenegada")
                mensaje = "Hola " + cliente.CliNom + "<hmtl><br/></html>"
                mensaje += "Su solicitud de participación en el mercado " + mercado + " ha sido "
                mensaje += cliente.Aceptar ? "aceptada" : "rechazada"
                mensaje += "Kaixo " + cliente.CliNom + "<hmtl><br/></html>"
                mensaje += mercado + " merkatuan parte hartzeko egin "
                mensaje += cliente.Aceptar ? "duzun eskaera onartu egin da." : "zenuen eskaera atzera bota dute."
                */
                msgEU = "<p>Sareko.net plataformako merkatu berri batean erosketak egiteko zure eskaera ezeztatu egin da. "
                msgEU += "Honen arrazoiak ezagutu nahi badituzu jarri mesedez koordinatzailearekin harremanetan, "
                msgEU += "telf. " + CooTel + " edo email: " + CooMai + ". </p>"
                msgEU += "<p>Bestelako erosketa aukeraren bat aztertzen laguntzea nahi baduzu jarri gurekin harremanetan "
                msgEU += "sustatu@ekolurra.eus edo telf. 946271594. </p>"
                msgEU += "<p>Ondo izan. ENEEK-Ekolurrako komunikazio saila.</p>"
                //
                msgES = "<p>El productor ha denegado su solicitud de alta en el mercado " + mercado + ". "
                msgES += "Para tener más información ponte en contacto con la persona coordinadora del mercado "
                msgES += "telf. " + CooTel + " o email " + CooMai + ". </p>"
                msgES += "<p>Si quiere que valoremos alguna otra opción de compra póngase en contacto con nosotros en "
                msgES += "sustatu@ekolurra.eus o telf. 946271594. </p>"
                msgES += "<p>Un saludo. Departamento de comunicación de ENEEK-Ekolurra.</p>"
            }
            // Formatear HTML. //
            let mensaje = "<html><body>" + msgEU + '<hr width="100%" size="2"></hr>' + msgES + "</body></html>"

            enviaEmailSolicitud(cliente, asunto, mensaje).then((data) => {
                let error = ''
                if (data) {
                    //console.log("Datos respuesta: ", data)
                    // Error en el envío. //
                    if (data?.info?.rejected?.length > 0) {
                        data.info.rejectedErrors.map(rechazo => {
                            error = "Envio Rechazado: " + rechazo.recipient + "  Motivo: " + rechazo.response
                            // Return del map
                            return null
                        })
                    }
                }
                if (error === '') {
                    toast.info('Email Solicitud a: ' + cliente.CliEma, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        onClose: props => finEnviosMails(), // Recarga Datos
                    })
                    // Salta a pantalla Final - Email enviado
                    console.log('Envio OK ', cliente.CliEma)
                }
                else {
                    console.log('Envio Incorrecto: ', error)
                }
            })
        })
        setEstado({ ...estado, verProgreso: false })

    }

    function finEnviosMails() {
        //estado.lineasClientes = []
        setEstado(estado => ({ ...estado, carga: true, lineasClientes: [] }))
    }


    const listaClientes = (
        estado.lineasClientes.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("Clientes.ClienteSolicitante")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("Clientes.Email")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("Clientes.Idioma")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("Clientes.MercadoSolicitado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("Clientes.FechaSolicitud")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("Clientes.AceptarSolicitud")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("Clientes.DenegarSolicitud")}</Typography></TableCell>

                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {estado.lineasClientes.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.CliCod + " - " + l.CliNom + " - " + l.CliTel + " - " + l.DirPob}</TableCell>
                                    <TableCell>{l.CliEma}</TableCell>
                                    <TableCell>{l.SolIdi === "Es" ? (state.idioma === "Es" ? "Español" : "Gaztelania") : (state.idioma === "Eus" ? "Euskara" : "Euskera")}</TableCell>
                                    <TableCell>{l.SolMer + ' - ' + (state.idioma === "Es" ? l.MerDesC : l.MerDesE)}</TableCell>
                                    <TableCell>{l.SolFec}</TableCell>
                                    <TableCell align="right">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<Checkbox
                                                id={"CliAce" + l.CliCod + l.SolMer}
                                                checked={l.Aceptar}
                                                onChange={handleChange("Aceptar", l)}
                                            />}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<Checkbox
                                                id={"CliDen" + l.CliCod + l.SolMer}
                                                checked={l.Denegar}
                                                onChange={handleChange("Denegar", l)}
                                            />}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Grid container display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {t("Clientes.Aceptar")}
                    </Button>
                    <ToastContainer />
                </Grid>
            </Grid>
        )
    )

    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3 }}>
                    <Typography variant="verde30">
                        {t("Clientes.SolicitudesDeAlta")}
                    </Typography>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaClientes}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(Clientes)