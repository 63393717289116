import config from './../config/config.js'

const rutaApi = config.rutaApis + '/solicitudes'



/////////////////////////////////////////////////////////
// Lista Solicitudes Acceso a Mercados de un Productor //
/////////////////////////////////////////////////////////
const listaSolicitudes = (params) => {
  return fetch(rutaApi + '/listaSolicitudes/' + params.proveedor, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



export {
  listaSolicitudes
}